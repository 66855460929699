import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../application/store/useStore";
import './style.css';
import { AiOutlineRollback } from "react-icons/ai";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../application/store";
import ClientOverviewPage from "./over-view-client";
import HealthMetricsPage from "./health-metrics";
import ClientAlertsPage from "./client-alerts";
import ClientMedicationPage from "./client-medication";
import { getProfileAsync } from "../../../../../application/features/user-profile-service/userProfileServiceThunk";
import { getDeviceByUserIdAsync } from "../../../../../application/features/device-service/deviceServiceThunk";

import { DeviceByUserIdDto } from "../../../../../application/features/device-service/models/DeviceDto";
import { getContactsAsync } from "../../../../../application/features/contact-service/contactServiceThunk";
import ClientSummaryPage from "./client-summary";
import ClientMonthlyReportsPage from "./client-monthly-reports";
import ClientVitalsReportsPage from "./health-metrics-v2";

const ClientDetail = () => {
    const [activeTab, setActiveTab] = useState('monthlyReports'); // Set default tab here

    const { user_profile, userAlerts } = useAppSelector((state: RootState) => state.userProfileService);
    const { device } = useAppSelector((state: RootState) => state.deviceService);

    const { contactList } = useAppSelector((state: RootState) => state.contactService);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {

        if (params.id) {
            dispatch(getProfileAsync(params.id));
            dispatch(getDeviceByUserIdAsync({ user_id: params.id }));
        }
    }, [dispatch, params.id]);

    useEffect(() => {

        if (user_profile?.sid) {

            dispatch(getContactsAsync(user_profile.sid));
        }
    }, [dispatch, user_profile?.sid]);

    return (
        <>
            <div className="user-profile">
                <h3><AiOutlineRollback className="cursor-pointer me-2 text-primary" onClick={() => navigate(-1)}></AiOutlineRollback> {` ${user_profile?.first_name} , ${user_profile?.last_name} ${user_profile?.gender.toLowerCase() === "male" ? '(Mr.)' : '(Ms.)'}`}</h3>
                <div className="tab-container mt-4">
                    <Tabs
                        defaultActiveKey="monthlyReports"
                        id="justify-tab-example"
                        className="tabs flex-wrap mb-2"
                        justify
                        onSelect={(selectedTab) => setActiveTab(selectedTab ?? '')} // Detect tab change
                    >
                        <Tab eventKey="monthlyReports" title="Monthly Reports">
                            <ClientMonthlyReportsPage key={`monthly reports`} user_profile_sid={params.id} />
                        </Tab>
                        {/* <Tab eventKey="healthMetrics" title="Health Insights">
                          <ClientVitalsReportsPage key={`health metrics`} user_profile_sid={params.id} />
                        </Tab> */}
                        <Tab eventKey="healthMetricsTest" title="Health Insights">
                            {activeTab === 'healthMetricsTest' && (
                                <HealthMetricsPage key={`health metrics`} id={params.id} />
                            )}
                            {/* <HealthMetricsPage key={`health metrics`} id={params.id} /> */}
                        </Tab>

                        <Tab eventKey="healthSummary" title="Health Summary">
                            {activeTab === 'healthSummary' && (
                                <ClientSummaryPage key={`health summary`} user_sid={params.id} />
                            )}
                            {/* <ClientSummaryPage key={`health summary`} user_sid={params.id} /> */}
                        </Tab>

                        <Tab eventKey="medical" title="Medical">
                            {activeTab === 'medical' && (
                                <ClientMedicationPage key={`client medication`} user_sid={params.id} />
                            )}
                            {/* <ClientMedicationPage key={`client medication`} user_sid={params.id} /> */}
                        </Tab>
                        <Tab eventKey="alertsAndIncidents" title="Alerts and Health Incidents">
                            {activeTab === 'alertsAndIncidents' && (
                                <ClientAlertsPage key={`alerts-page`} id={params.id} />
                            )}
                            {/* <ClientAlertsPage key={`alerts-page`} id={params.id} /> */}
                        </Tab>
                        <Tab eventKey="overview" title="Profile">
                            {activeTab === 'overview' && (
                                <>
                                    {
                                        user_profile && userAlerts && userAlerts.data
                                        && <ClientOverviewPage client={user_profile} userAlert={userAlerts.data[0]}
                                            device={device}
                                            contactList={contactList} />
                                    }</>
                            )}

                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )

}

export default ClientDetail;